.ReactTable {
  border: none;
}

.ReactTable .rt-noData {
  padding: 20px;
  margin-top: 48px;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tbody .rt-td {
  border: none;
}
