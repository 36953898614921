/* == Typography == */

html {
  font-size: 20px;
  font-weight: 300;
}

body {
  color: #1e1e1e; /* Black */
  font-family: Amplitude, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* == Fonts == */

@font-face {
  font-family: 'Amplitude';
  font-style: normal;
  font-weight: 200; /* Light */
  src: url('https://cdn.finnair.com/finnair-com-ux/fonts/AmplitudeLight.woff2') format('woff2'),
    url('https://cdn.finnair.com/finnair-com-ux/fonts/AmplitudeLight.woff') format('woff');
}

@font-face {
  font-family: 'Amplitude';
  font-style: normal;
  font-weight: 300; /* Book */
  src: url('https://cdn.finnair.com/finnair-com-ux/fonts/AmplitudeBook.woff2') format('woff2'),
    url('https://cdn.finnair.com/finnair-com-ux/fonts/AmplitudeBook.woff') format('woff');
}

@font-face {
  font-family: 'Amplitude';
  font-style: normal;
  font-weight: 400; /* Regular */
  src: url('https://cdn.finnair.com/finnair-com-ux/fonts/AmplitudeRegular.woff2') format('woff2'),
    url('https://cdn.finnair.com/finnair-com-ux/fonts/AmplitudeRegular.woff') format('woff');
}

@font-face {
  font-family: 'Amplitude';
  font-weight: 500; /* Medium */
  src: url('https://cdn.finnair.com/finnair-com-ux/fonts/AmplitudeMedium.woff2') format('woff2'),
    url('https://cdn.finnair.com/finnair-com-ux/fonts/AmplitudeMedium.woff') format('woff');
}

@font-face {
  font-family: 'Amplitude';
  font-style: normal;
  font-weight: 700; /* Bold */
  src: url('https://cdn.finnair.com/finnair-com-ux/fonts/AmplitudeBold.woff2') format('woff2'),
    url('https://cdn.finnair.com/finnair-com-ux/fonts/AmplitudeBold.woff') format('woff');
}
