.day-picker-no-flights {
  color: silver;
}

.day-picker-selected {
  background-color: #ba4a9e;
  color: white;
}

.day-picker-selected:hover {
  --rdp-background-color: #ba4a9e;
  border: 2px solid #e7edff;
  color: white;
}

.rdp-button:hover {
  border: 2px solid #e7edff;
}

.rdp-month {
  margin: auto;
}

.rdp-month:first-child {
  margin: auto;
}

.rdp-month:last-child {
  margin: auto;
}

.rdp-head_cell {
  color: grey;
  font-weight: 400;
}

.rdp-caption_label {
  font-weight: 400;
}
